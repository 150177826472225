import React from "react";
import {AppsOutlined, HomeOutlined, NotificationsOutlined,} from "@mui/icons-material";
import {TabInfo, Tabs} from "shared/TabsContainer";
import {AppTabsContainer, AppTabsContainerProps, AppTabsContainerState} from "shared/AppTabsContainer";
import {PathComponent, PathProps} from "index";
import {Box, Button, Tooltip} from "@mui/material";
import {BORDER_RADIUS, DIVIDER, PD_SM, PD_XXXSM, SZ_SM} from "shared/dimens";
import {StyledBadge} from "shared/StyledComponents";
import {PluginConfig} from "../shared/types";
import {THEME_OPTIONS} from "./App";
import {getLoginCredentials} from "../shared/BaseApp";
import {FIREBASE_OPTIONS} from "../consts";
import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../shared/BaseFragment";
import {PluginConfigProvider, Plugins} from "../shared/plugins";
import {AppPrefs} from "./app_prefs";
import {white} from "../shared/colors";
import {MembersKey} from "../shared/entities";
import {PlaceholderFragment} from "../shared/PlaceholderFragment";

export enum TabType {
  HOME = "home",
  PLUGINS = "plugins",
}

function createPluginConfig(): PluginConfig {
  return {
    app: {
      themeOptions: THEME_OPTIONS,
      firebase: FIREBASE_OPTIONS,
    },
    membersKey: MembersKey.DEFAULT,
    autoLogin: getLoginCredentials(),
  }
}

const TABS: Tabs<TabType> = {
  items: [
    {
      type: TabType.HOME,
      path: "home",
      text: "Home",
      iconType: HomeOutlined,
      render: (path: PathProps) => <PlaceholderFragment path={path}/>,
    },
    {
      type: TabType.PLUGINS,
      path: ":plugin_id",
      groupType: "plugins",
      plugins: [],
    },
  ],
  containerId: "main",
};

type MainTabsContainerProps = AppTabsContainerProps<TabType> & {}

type MainTabsContainerState = AppTabsContainerState<TabType> & {}

class MainTabsContainer extends AppTabsContainer<TabType, MainTabsContainerProps, MainTabsContainerState> {

  protected renderToolbar(): React.ReactElement {
    return <Box style={{
      display: "flex",
      paddingLeft: PD_SM,
      paddingRight: PD_SM,
      alignItems: "center",
      position: "relative",
    }}>
      <Box style={{display: "flex"}}>
        <Box style={{display: "flex", border: DIVIDER, borderRadius: BORDER_RADIUS, alignItems: "center", background: white, zIndex: 1}}>
          <img src={"/logotype.png"} style={{height: SZ_SM}}/>
          {/*<Typography variant="h5" style={{ paddingLeft: PD_SM, paddingRight: PD_SM, paddingTop: PD_XXSM, paddingBottom: PD_XXSM, maxWidth: 240, overflow: "hidden", whiteSpace:"nowrap", textOverflow: "ellipsis"}}>Fyne.ai</Typography>*/}
        </Box>
      </Box>
      <span style={{flexGrow: 1}}/>
      <Box style={{display: "flex", gap: PD_XXXSM}}>
        <Tooltip title="Notifications">
          <StyledBadge badgeContent={1}>
            <Button onClick={() => this.props.path.navigate("/notifications")}>
              <NotificationsOutlined/>
            </Button>
          </StyledBadge>
        </Tooltip>
      </Box>
    </Box>;
  }
}

export type MainProps = BaseFragmentProps & {}

type MainState = BaseFragmentState & {}

export class Main extends BaseFragment<MainProps, MainState> implements PluginConfigProvider {

  private readonly appPrefs = AppPrefs.getInstance();

  private readonly plugins = Plugins.initInstance(this.appPrefs.getPluginUrls(), this);

  constructor(props: MainProps, context: any) {
    super(props, context);
    this.state = {}
  }

  static nestedPaths(): PathComponent[] {
    return MainTabsContainer.nestedPathsFromTabs(TABS);
  }

  getPluginConfig() {
    return createPluginConfig();
  }

  protected async fetchOnMount(): Promise<void> {
    const tabInfo: TabInfo<TabType> = TABS.items.find(item => item.type === TabType.PLUGINS);
    tabInfo.plugins = await this.plugins.getOrLoadPlugins();
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <MainTabsContainer logo="/icon_w.png" path={this.props.path} tabs={TABS}/>;
  }
}
