export const FIREBASE_OPTIONS = {
  apiKey: "AIzaSyDDIBEaYRx_5lSb0_fOVRXnP9wV3e1SBEA",
  authDomain: "fyneapps-apps.firebaseapp.com",
  projectId: "fyneapps-apps",
  storageBucket: "fyneapps-apps.appspot.com",
  messagingSenderId: "260329543886",
  appId: "1:260329543886:web:cb7740c6a4154de5ccd89f",
  measurementId: "G-8LPW98RCL6"
};

export const THEME_COLOR_PRIMARY = "#00272d";
export const THEME_COLOR_PRIMARY_CONTRAST = "#fff";
export const THEME_COLOR_SECONDARY = "#fff";
export const THEME_COLOR_SECONDARY_CONTRAST = "#000";