import {AppConfig, BaseApp, BaseAppProps, BaseAppState, clearProvisioningId} from "shared/BaseApp";
import React, {ReactElement} from 'react';
import {createTheme, ThemeOptions} from "@mui/material";
import {DEFAULT_THEME_OPTIONS} from "../shared/colors";
import {AppPrefListener, AppPrefs} from "./app_prefs";
import {Main} from "./Main";
import {PathComponent} from "../index";
import {
  FIREBASE_OPTIONS,
  THEME_COLOR_PRIMARY,
  THEME_COLOR_PRIMARY_CONTRAST,
  THEME_COLOR_SECONDARY,
  THEME_COLOR_SECONDARY_CONTRAST
} from "../consts";

export type AppProps = BaseAppProps & {}

type AppState = BaseAppState & {}

export const THEME_OPTIONS: ThemeOptions = {
  ...DEFAULT_THEME_OPTIONS,
  palette: {
    primary: {
      main: THEME_COLOR_PRIMARY,
      contrastText: THEME_COLOR_PRIMARY_CONTRAST,
    },
    secondary: {
      main: THEME_COLOR_SECONDARY,
      contrastText: THEME_COLOR_SECONDARY_CONTRAST,
    },
  }
};

export const THEME = createTheme(THEME_OPTIONS);

export class App extends BaseApp<AppProps, AppState> implements AppPrefListener {

  private readonly appPrefs = AppPrefs.getInstance();

  onCreateAppConfig(): AppConfig {
    return {
      name: "Fyneapps Inhouse",
      icon: "/icon.png",
      logo: "/logo192.png",
      stamp: "/stamp.png",
      stampText: "Inhouse",
      defaultUserImage: "/images/default_user.png",
      theme: THEME,
      firebaseConfig: {
        options: FIREBASE_OPTIONS,
      },
      loginConfig: {
        provisioning: {
          signupDisabled: true,
        },
      },
      systemPluginsConfig: {
        pluginUrls: [
          "https://fyneapps-shared-plugin-provisionings.web.app",
        ],
      }
    };
  }

  getCollapseSidebar(): boolean {
    return this.appPrefs.getCollapseSidebar();
  }

  setCollapseSidebar(collapse: boolean) {
    this.appPrefs.setCollapseSidebar(collapse);
  }

  componentDidMount() {
    super.componentDidMount();
    this.appPrefs.addListener(AppPrefs.PREF_COLLAPSE_SIDEBAR, this);
  }

  componentWillUnmount() {
    this.appPrefs.removeListener(AppPrefs.PREF_COLLAPSE_SIDEBAR, this);
  }

  onPrefChanged(key: string) {
    switch (key) {
      case AppPrefs.PREF_COLLAPSE_SIDEBAR:
        this.notifyAppEvent("collapse_sidebar");
        break;
    }
  }

  static nestedPaths(): PathComponent[] {
    return Main.nestedPaths();
  }

  protected renderMain(): ReactElement {
    return <>
      <Main path={this.props.path}/>
    </>;
  }
}
